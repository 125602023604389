import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../src/components/layout/Layout";
import Dashboard from "./Pages/Client/Dashboard";
import LoadingSpinner from "./components/UI/LoadingSpinner/LoadingSpinner";
import { useSelector } from "react-redux";
import "tailwindcss/tailwind.css";

const NotFound = React.lazy(() => import("./Pages/Client/NotFound"));
const AuthPage = React.lazy(() => import("./Pages/Client/AuthPage"));
const LoginAdminPage = React.lazy(() => import("./Pages/Admin/LoginAdminPage"));
const NewCustomer = React.lazy(() => import("../src/Pages/Admin/NewCustomer"));
const AdminPage = React.lazy(() => import("../src/Pages/Admin/AdminPage"));
const GreenHousePage = React.lazy(() =>
  import("../src/Pages/Client/GreenHousePage")
);
const GreenHouseDetail = React.lazy(() =>
  import("../src/Pages/Client/GreenHouseDetail")
);

function App() {
  const isAdminLoggedIn = useSelector((state) => state.admin.isLoggedIn);
  const isCustomerLoggedIn = useSelector((state) => state.customer.isLoggedIn);

  return (
    <Layout>
      <Suspense
        fallback={
          <div className="centered">
            <LoadingSpinner />
          </div>
        }
      >
        <Switch>
          <Route path="/" exact>
            {!isCustomerLoggedIn && <Redirect to="/autentificare" />}
            {isCustomerLoggedIn && <Dashboard />}
          </Route>

          <Route path="/autentificare">
            {!isCustomerLoggedIn && <AuthPage />}
            {isCustomerLoggedIn && <Dashboard />}
          </Route>

          <Route path="/dashboard">
            {isCustomerLoggedIn && <Dashboard />}
            {!isCustomerLoggedIn && <Redirect to="/autentificare" />}
          </Route>

          <Route path="/sere">
            {isCustomerLoggedIn && <GreenHousePage />}
            {!isCustomerLoggedIn && <Redirect to="/autentificare" />}
          </Route>

          <Route path="/sera/:numesera">
            {isCustomerLoggedIn && <GreenHouseDetail />}
            {!isCustomerLoggedIn && <Redirect to="/autentificare" />}
          </Route>

          <Route path="/admin-login">
            {!isAdminLoggedIn && <LoginAdminPage />}
            {isAdminLoggedIn && <Redirect to="/admin" />}
          </Route>

          <Route path="/admin">
            {!isCustomerLoggedIn && <Redirect to="/" />}
            {isAdminLoggedIn && <AdminPage />}
            {isCustomerLoggedIn && <Redirect to="*" />}
          </Route>

          {isAdminLoggedIn && (
            <Route path="/adaugare-client">
              <NewCustomer />
            </Route>
          )}

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default App;
