import styles from "./SimpleButton.module.css";
const SimpleButton = (props) => {
  let classes;
  if (props.anotherStyle) {
    classes = `${props.className}`;
  } else {
    classes = `${styles.btn}`;
  }

  return (
    <button
      className={classes}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default SimpleButton;
