import { createSlice } from "@reduxjs/toolkit";

let item = JSON.parse(localStorage.getItem("addedCustomer"));
let contractStep = item === null ? false : item.contractStep;
let contractStatus = item === null ? "Adaugare Client" : item.contractStatus;

const adminCustomerInitialState = {
  customers: [],
  totalCustomers: 0,
  contractStep: contractStep,
  status: contractStatus,
  addNewCustomer: false, // for modal window
}; // i don`t know if we really need totalCustomers.

const adminCustomerSlice = createSlice({
  name: "adminCustomer",
  initialState: adminCustomerInitialState,
  reducers: {
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    addNewCustomer(state) {
      state.addNewCustomer = !state.addNewCustomer;
    },
    // Should have a contract number generator!
    clientWasMade(state, action) {
      state.contractStep = !state.contractStep;
      localStorage.setItem("addedCustomer", JSON.stringify(action.payload));
      state.status = action.payload.contractStatus;
    },
    contractWasMade(state) {
      state.contractStep = !state.contractStep;
      state.status = "Adaugare client";
      localStorage.removeItem("addedCustomer");
    },
  },
});

export const adminCustomerActions = adminCustomerSlice.actions;
export default adminCustomerSlice.reducer;
