import { Fragment } from "react";
import MainNavigation from "./MainNavigation";
import Footer from "./Footer";
const Layout = (props) => {
  return (
    <Fragment>
      <MainNavigation />
      <main>
        <div>{props.children}</div>
      </main>
      <footer>
        <Footer />
      </footer>
    </Fragment>
  );
};

export default Layout;
